export const PRODUCTS_BATCH_DELETE_REQUEST = 'PRODUCTS_BATCH_DELETE_REQUEST'
export const PRODUCTS_BATCH_DELETE_SUCCESS = 'PRODUCTS_BATCH_DELETE_SUCCESS'
export const PRODUCTS_BATCH_DELETE_FAILURE = 'PRODUCTS_BATCH_DELETE_FAILURE'

export const PRODUCTS_FETCH_MANY_REQUEST = 'PRODUCTS_FETCH_MANY_REQUEST'
export const PRODUCTS_FETCH_MANY_SUCCESS = 'PRODUCTS_FETCH_MANY_SUCCESS'
export const PRODUCTS_FETCH_MANY_FAILURE = 'PRODUCTS_FETCH_MANY_FAILURE'

export const PRODUCTS_FETCH_REQUEST = 'PRODUCTS_FETCH_REQUEST'
export const PRODUCTS_FETCH_SUCCESS = 'PRODUCTS_FETCH_SUCCESS'
export const PRODUCTS_FETCH_FAILURE = 'PRODUCTS_FETCH_FAILURE'

export const PRODUCTS_SAVE_REQUEST = 'PRODUCTS_SAVE_REQUEST'
export const PRODUCTS_SAVE_SUCCESS = 'PRODUCTS_SAVE_SUCCESS'
export const PRODUCTS_SAVE_FAILURE = 'PRODUCTS_SAVE_FAILURE'

export const EMPTY_PRODUCTS = 'EMPTY_PRODUCTS'
export const CLEAR_ERRORS = 'CLEAR_ERRORS'
